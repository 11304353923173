import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import SEO from "../components/SEO"
import Img from "gatsby-image"
import Trumps from "../components/Trumps"

const AboutPage = () => {
  const { data, data2 } = useStaticQuery(graphql`
    {
      data: markdownRemark(frontmatter: { id: { eq: "about" } }) {
        html
        ...SEO
        frontmatter {
          title
          formattedTitle: formatted_title
          services
          image {
            childImageSharp {
              fluid(maxWidth: 2160, maxHeight: 1111, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      data2: markdownRemark(frontmatter: { id: { eq: "about-2" } }) {
        html
      }
    }
  `)

  const {
    frontmatter: { title, ogImage, image, formattedTitle, services },
    html,
  } = data

  return (
    <>
      <SEO title={title} ogImage={ogImage} />

      <Layout>
        <div className="c-page-title">
          <div className="u-inner">
            <h1 dangerouslySetInnerHTML={{ __html: formattedTitle }} />
          </div>
        </div>

        <div className="c-paragraphs">
          <div className="c-paragraph">
            <div className="c-paragraph__inner">
              <div className="o-grid o-grid--middle">
                <div className="o-grid__cell u-1/1 u-1/2@desktop">
                  <div
                    className="c-body"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
                <div className="o-grid__cell u-1/1 u-1/2@desktop">
                  <aside className="c-aside">
                    <ul className="c-aside__list">
                      {services.map(service => (
                        <li key={service}>{service}</li>
                      ))}
                    </ul>
                  </aside>
                </div>
              </div>
            </div>
          </div>

          <div className="c-paragraph c-paragraph--image">
            <Img
              style={{ width: "100%", height: "100%" }}
              fluid={image.childImageSharp.fluid}
            />
          </div>

          <div className="c-paragraph">
            <div className="c-paragraph__inner">
              <div
                className="c-body"
                dangerouslySetInnerHTML={{ __html: data2.html }}
              />
            </div>
          </div>
        </div>

        <Trumps full />
      </Layout>
    </>
  )
}

export default AboutPage
